@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #f5f5f5;
  margin: 0;
  font-family: "Nunito", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  zoom: 0.9;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* ::-webkit-scrollbar-track {
  background: #f1f1f1;
} */

code {
  font-family: "Nunito", sans-serif;
}

.active-tab {
  background-color: #f4f5fa;
  color: black;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
  scrollbar-width: none;
  /* Hide scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #cac9c9;
  /* Color of the scrollbar thumb */
  border-radius: 8px;
  /* Radius of the scrollbar thumb */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: white;
  /* Color of the scrollbar track */
}
.custom-scrollbar2::-webkit-scrollbar {
  width: 8px;
  height: 2px;
  scrollbar-width: 1px;
  /* Hide scrollbar */
}

.custom-scrollbar2::-webkit-scrollbar-thumb {
  background-color: #cac9c9;
  /* Color of the scrollbar thumb */
  border-radius: 8px;
  /* Radius of the scrollbar thumb */
}

.custom-scrollbar2::-webkit-scrollbar-track {
  background-color: white;
  /* Color of the scrollbar track */
}

/* CSS */
.custom-polifyx-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #d7dbec transparent;
  -ms-overflow-style: none;
  overflow-y: scroll;
  overflow-x: hidden;
  border-radius: 16px;
}

.shadow-right {
  box-shadow: 8px 0px 16px rgba(149, 157, 165, 0.2);
}

.shadow-top {
  box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
}

.shadow-bottom {
  box-shadow: inset 0px -8px 24px rgba(149, 157, 165, 0.2);
}

.shadow-all {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
}

.shadow-above-border {
  position: relative;
}

.shadow-above-border::before {
  content: "";
  position: absolute;
  top: -20px;
  left: 0;
  right: 0;
  height: 20px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0));
}

.shadow-below-border {
  position: relative;
}

.shadow-below-border::before {
  content: "";
  position: absolute;
  bottom: -10px; /* Position the shadow 10px below the element */
  left: 0;
  right: 0;
  height: 20px; /* Height of the shadow */
  background: linear-gradient(to top, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0)); /* Gradient for the shadow */
}

@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap");
