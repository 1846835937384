.carousel {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    overflow: hidden;
}

.carousel__content {
    display: flex;
    transition: transform 2s ease; /* Updated to 2 seconds for smoother transitions */
    width: 100%;
}

.carousel__item {
    min-width: 100%;
    /* No transition or opacity changes needed for sliding effect */
}

.carousel__button {
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    color: white;
    font-size: 2rem;
    cursor: pointer;
    z-index: 2;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.carousel__button--left {
    left: 0;
}

.carousel__button--right {
    right: 0;
}
